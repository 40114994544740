const browser = (() => {
  const test = (regexp) => regexp.test(window.navigator.userAgent);
  switch (true) {
    case test(/edg/i):
      return "Microsoft Edge";
    case test(/trident/i):
      return "Microsoft Internet Explorer";
    case test(/firefox|fxios/i):
      return "Mozilla Firefox";
    case test(/opr\//i):
      return "Opera";
    case test(/ucbrowser/i):
      return "UC Browser";
    case test(/samsungbrowser/i):
      return "Samsung Browser";
    case test(/chrome|chromium|crios/i):
      return "Google Chrome";
    case test(/safari/i):
      return "Apple Safari";
    default:
      return "Other";
  }
})();

const REDIRECT_BROWSERS = [
  "Microsoft Internet Explorer",
  "Mozilla Firefox",
  "Opera",
  "UC Browser",
  "Samsung Browser",
  "Apple Safari",
];
const EXCLUDED_FROM_REDIRECT = [
  "/deliverability/report",
  "/onboarding/signup",
  "/onboarding/contract",
  "/onboarding/payment",
  "/onboarding/success",
  "/onboarding/error",
];

const imgNotFound = (e) => {
  $(e.target).unbind("error").attr("src", "/assets/img/icons/user.png");
};

export const initializeConfig = () => {
  if (
    REDIRECT_BROWSERS.includes(browser) &&
    !EXCLUDED_FROM_REDIRECT.includes(window.location.pathname)
  )
    window.location = "/signin/browser-redirect";

  Sentry.init({
    dsn: "https://676c3a1b0ae44248b1507b900f6b8c1e@o358760.ingest.sentry.io/5260818",
    integrations: [
      new Sentry.Integrations.CaptureConsole({ levels: ["error"] }),
      new Sentry.Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 0.01,
    ignoreErrors: ["Non-Error exception captured"],
    release: "SENTRY_RELEASE_NAME",
  });

  $(".photo-image").on("error", imgNotFound);

  $(document).ajaxComplete(() => {
    $(".photo-image").on("error", imgNotFound);
  });
};
