import {
  cookieValue,
  diallerVersion,
  getUser,
  saveUser,
  showToast,
  urlParams,
  uuidV4,
  wrappedGet,
  wrappedPost,
  yesNoConfirm,
} from "./utils.js";
import { addWebsocketListener } from "./websocket.js";

import { userStore } from "../../../../svelte/api-stores/user-store.js";

const { pathname, search } = window.location;

// eslint-disable-next-line no-useless-escape

const CREDITS = {};

export const getCredits = (creditType) => {
  if (creditType in CREDITS) {
    return CREDITS[creditType];
  }
  throw new Error(`Invalid credit type: ${creditType}`);
};

const handleRestrictedAccount = (res) => {
  if (res.isTest && ["/projects", "/project"].includes(pathname)) {
    const HIDE_TOP_BAR = {
      "/projects": [
        "statusButtons",
        "viewActiveBtn",
        "viewArchivedBtn",
        "projectsTable_filter",
        "projectsUserSelector",
      ],
      "/project": [
        "projectTagSelector",
        "projectCampaignSelector",
        "projectStageSelector",
        "projectButtonsDiv",
      ],
    };
    HIDE_TOP_BAR[pathname].forEach((x) => {
      $(`#${x}`).hide();
    });
  }

  $("#top-container").removeClass("skeleton-loading");

  if (!(res.isTest || res.isExtensionOnly)) return;

  const ALLOWED_PAGES = [
    "/admin",
    "/contacts",
    "/integrations",
    "/options",
    "/project",
    "/projects",
    "/review",
    "/signin",
    "/upload",
  ];
  if (res.isTest) {
    ALLOWED_PAGES.push(...["/outreach"]);
  }
  if (!ALLOWED_PAGES.includes(pathname)) window.location.replace("/contacts");

  if (res.isTest || res.isExtensionOnly) {
    const HIDE_PROFILE_OPTIONS = ["profileDropdown", "unsubscribesDropdown"];
    if (res.isTest) {
      HIDE_PROFILE_OPTIONS.push(
        ...["extensionDropdown", "trainingDropdown", "featureRequestDropdown"],
      );
    }
    HIDE_PROFILE_OPTIONS.forEach((x) => {
      $(`#${x}`).hide();
    });
  }
};

const signOut = () =>
  wrappedGet({
    url: "/api/users/sign_out",
    success: () => {
      localStorage.removeItem("provider");
      saveUser({});
      window.location = "/signin";
    },
  });

const displaySwitchTeams = (user) => {
  wrappedGet({
    url: "/api/organizations/get",
    data: { organizationId: user.organizationId },
    success: (res) => {
      const { organization } = res;
      $("#adminDropdownDiv").append(/* html */ `
          <div class="dropdown-divider"></div>
          <li id="viewTeams" class="dropdown-item contains-submenu">
            <span class="d-flex align-items-center">
              <i class="fa-regular fa-people-group fa-fw mr-2"></i>View Teams<i class="fa-solid fa-angles-right ml-1 mt-1" style="font-size:60%"></i>
              <div class="submenu right dropdown-menu"></div>
            </span>
          </li>`);
      const alphabeticallySortedTeam = [...organization.teams].sort((a, b) =>
        a.teamName.localeCompare(b.teamName),
      );
      alphabeticallySortedTeam.forEach((team) => {
        if (team.teamId === user.teamId) return;
        $("#viewTeams .submenu").append(
          `<a id="switch-to-${team.teamId}" class="dropdown-item">View ${team.teamName}</a>`,
        );
        $(`#switch-to-${team.teamId}`).click(() => {
          window.location = `/api/admin/impersonate?teamId=${team.teamId}&path=${pathname}`;
        });
      });
    },
  });
};

const displayTeamGroups = (user) => {
  let groups = user.teamGroups;

  const compareGroupNames = (a, b) =>
    a.groupName.toLowerCase().localeCompare(b.groupName.toLowerCase());

  if (!groups) return;
  groups = Object.entries(groups).map(([groupId, v]) => ({ groupId, ...v }));
  groups = groups.sort((a, b) => compareGroupNames(a, b));

  const page = window.location.pathname;

  let showGroup;
  if (groups.length > 0) {
    $("#teamGroupSelectorWrapper").show();
    const teamGroupSelector = $("#teamGroupSelector");
    teamGroupSelector.find("option").remove();

    if (page === "/admin")
      teamGroupSelector.append(
        $("<option>", { value: "_none", text: "No Group" }),
      );
    else
      teamGroupSelector.append(
        $("<option>", { value: "_all", text: "All Users" }),
      );

    groups.forEach((g) => {
      const opts = { value: g.groupId, text: g.groupName };
      teamGroupSelector.append($("<option>", opts));
      if (g.users.includes(user.userId)) {
        showGroup = g.groupId;
      }
    });

    const reportGroupSelector = $("#candidateStatsGroupBySelector");

    if (reportGroupSelector.length) {
      reportGroupSelector.append(
        '<option value="teamGroups" data-content="Team Group">Group by: Group</option>',
      );
      reportGroupSelector.selectpicker("refresh");
    }

    if (teamGroupSelector.length) {
      teamGroupSelector.selectpicker("refresh");
    }

    if (["/dashboard", "/"].includes(page) && showGroup) {
      teamGroupSelector.selectpicker("val", showGroup);
    }
  }
};

const setTrainingProgress = (trainingProgress, userId) => {
  trainingProgress = trainingProgress || [];

  const markTaskComplete = (taskId, key) => {
    if (!trainingProgress.includes(taskId))
      wrappedPost({
        url: "/api/users/save",
        data: JSON.stringify({ userId, updates: { [key]: true } }),
      });
  };

  if (pathname === "/dashboard") {
    setTimeout(() => {
      markTaskComplete("understand-the-dashboard", "hasVisitedDashboard");
    }, 1000 * 10);
  }

  if (pathname === "/todo") {
    setTimeout(() => {
      markTaskComplete("navigating-todos-page", "hasVisitedTodos");
    }, 1000 * 10);
  }

  if (pathname === "/campaign") {
    $("#settings-nav-tab-link").click(() => {
      markTaskComplete(
        "navigate-campaign-settings",
        "hasVisitedCampaignSettings",
      );
    });
  }

  if (pathname === "/review") {
    markTaskComplete("upload-spreadsheet", "hasUploadedSpreadsheet");
  }
};

const setCreditsCounter = (credits, key, plan = undefined) => {
  credits = credits || 0;
  if (key === "dialler" && plan === "unlimited") credits = "unlimited";

  $(`#${key}CounterNavItem`).removeClass("d-none").addClass("d-inline-flex");

  const suffix = key === "dialler" ? "Minutes" : "Credits";
  $(`#${key}${suffix}Counter`).text(credits);

  const counterIcon = $(`#${key}${suffix}CounterIcon`);

  const WARNINGS = {
    email: 10,
    phone: 10,
    dialler: 100,
  };
  const danger =
    key === "dialler" && plan === "unlimited" ? false : credits < WARNINGS.key;
  counterIcon.addClass(danger ? "text-danger" : "text-warning");
};

const displayDataProviders = (dataProviders) => {
  Object.entries(dataProviders || {}).forEach(([provider, item]) => {
    const id = uuidV4();
    $("#creditsContainer").append(/* html */ `
        <div
        id="${id}"
        class="d-inline-flex flex-column align-items-center user-select-none mx-2"
        data-toggle="tooltip"
        title="${item.title} Credits"
        >
          <span class="d-flex justify-content-center w-100 h-100" style="height: 1.8rem;">
            <img src="${item.icon}" style="width: 1rem; height: 1rem">
          </span>
          <span id="${provider}CreditsCounter" class="text-gray-600 font-weight-bold text-center" style="font-size: 0.6rem">${item.credits}</span>
        </div>`);
    $(`#${id}`)
      .tooltip("dispose")
      .tooltip({ title: `${item.title} Credits` });
  });
};

const addAlert = (alert) => {
  const link = alert.link || "#";
  $("#alertsMenu").append(`
      <a class="menu-alert d-flex align-items-center py-3" href="${link}" style="width:400px;white-space:normal">
        <div class="icon-container mr-3">
            <i class="${alert.icon}"></i>
        </div>
        <div>${alert.text}</div>
      </a>`);
};

const addIntegrationDisconnectedAlert = (alert) => {
  addAlert(alert);
  const { text, link, isAdmin, providerTitle } = alert;
  const adminText = `Your ${providerTitle} has been deactivated. <a href=${link}>Click here</a> to reactivate it.`;

  showToast({
    title: "Integration disconnected!",
    subtitle: isAdmin ? adminText : text,
    type: "warning",
    timeout: 45000,
  });
};

export const initializeVisibilities = () => {
  console.log(`

   ___  ___  _   _ _ __ ___ ___
  / __|/ _ \\| | | | '__/ __/ _ \\
  \\__ \\ (_) | |_| | | | (_|  __/
  |___/\\___/ \\__,_|_|  \\___\\___|

       ":"
       _:______    |"\\ /"|
     ,'        '.    \\ /
     |  O        \\___/ |
  ~^~^~^~^~^~^~^~^~^~^~^~^~^~^~
             _           _
   __      _| |__   __ _| | ___
   \\ \\ /\\ / / '_ \\ / _' | |/ _ \\
    \\ V  V /| | | | (_| | |  __/
     \\_/\\_/ |_| |_|\\__,_|_|\\___|

    `);

  if (cookieValue("impersonating") === "true") {
    $("#nav-sidebar").css("background-color", "#d81d1d");
    $("#depersonateNavItem")
      .show()
      .click(() => {
        window.location = "/internal-api/admin/depersonate";
      });

    $("#adminPanelNavItem")
      .show()
      .click(() => {
        window.location = "/internal/admin?tab=users";
      });
    $("#internalTickets").show();
  }

  if (cookieValue("demoing") === "true") {
    $("#stopDemo")
      .show()
      .click(() => {
        window.location = "/internal-api/demo/depersonate";
      });
  }

  $("#userName").text(getUser().name);

  $("#userImg").attr("src", getUser().picture);

  if (urlParams.get("provider")) {
    localStorage.setItem("provider", urlParams.get("provider"));
  }

  if (pathname !== "/signin" && !localStorage.getItem("provider")) {
    window.location.replace(`/signin?redirect=${pathname}${search}`);
  }

  addWebsocketListener((data) => {
    if (data.kind === "showToast") {
      const title = data.title || "";
      const subtitle = data.subtitle || "";
      const timeout = data.timeout || 10000;
      const type = data.type || "info";

      showToast({
        title,
        subtitle,
        timeout,
        type,
      });
    }

    if (data.kind === "hardRefresh") {
      window.location.reload(true);
    }

    if (data.kind === "getNewCookies") {
      wrappedGet({
        url: "/api/signin/get_new_cookies",
        success: () => {
          window.location.reload(true);
        },
      });
    }

    if (data.kind === "signOut") {
      if (data.toast) {
        localStorage.setItem("signOutToast", JSON.stringify(data.toast));
      }
      signOut();
    }

    if (data.kind === "update-teams-credit-history") {
      data.changes.forEach((change) => {
        ["emailCredits", "phoneCredits"].forEach((key) => {
          $(`#${key}Counter`).text(change.update[key]);
        });

        if (diallerVersion().plan === "standard") {
          $(`#diallerMinutesCounter`).text(change.update.diallerMinutes);
        }
      });
    }

    if (data.kind === "fileChange") {
      // hot reloading
      if (data.filename.endsWith(".css")) {
        const queryString = `?reload=${new Date().getTime()}`;
        $('link[rel="stylesheet"]').each((_, el) => {
          const href = $(el).attr("href");
          if (href.split("?")[0] === data.filename)
            $(el).attr("href", href.replace(/\?.*|$/, queryString));
        });
      }
      if (data.filename.endsWith(".js")) {
        $("script").each((_, el) => {
          const src = $(el).attr("src");
          if (src.split("?")[0] === data.filename) window.location.reload(true);
        });
      }
      if (data.filename.endsWith(".html")) {
        if (window.location.pathname === data.filename.split(".html")[0])
          window.location.reload(true);
      }
    }
  });

  userStore.once().then((storeData) => {
    const res = storeData.data;
    const home = res.isTest || res.isExtensionOnly ? "/contacts" : "/dashboard";
    $(".logo").attr("href", home);
    if (pathname === "/signin" && localStorage.getItem("provider")) {
      if (urlParams.get("stopRedirect") !== "true") {
        window.location.replace(home);
      }
    }

    saveUser(res);
    handleRestrictedAccount(res);

    if (pathname !== "/signin") localStorage.setItem("provider", res.provider);

    $("#userName").text(res.name);
    $("#userImg").attr("src", res.picture);

    Sentry.configureScope((scope) => {
      scope.setUser({ id: res.userId, username: res.name });
      scope.setTag("teamId", res.teamId);
    });

    if (cookieValue("impersonating") !== "true" && res.isInternalAdmin) {
      $("#adminPanelNavItem")
        .show()
        .click(() => {
          window.location = "/internal/admin?tab=users";
        });
    }

    if (res.demoProviders) {
      res.demoProviders.forEach((x) => {
        $("#demoDropdown").append(/* html */ `
              <a class="dropdown-item" id="demo-${x.provider}-button">
                  <img class="mr-2" src="/assets/img/crm/${x.provider}.png" style="width: 1rem; height: 1rem; margin-bottom: 0.15rem">${x.title}
              </a>`);

        $(`#demo-${x.provider}-button`).click(() => {
          window.location = `/internal-api/demo/impersonate?provider=${x.provider}`;
        });
      });

      $("#demoDropdownWrapper").show();
    }

    if (res.isInternal) {
      $("#internalTickets").show();
    }

    if (res.isAdmin && res.organizationId && !res.isTest) {
      displaySwitchTeams(res);
    }
    displayTeamGroups(res);

    if (res.isOnAnotherTeam) {
      showToast({
        title: `You are currently viewing ${res.teamName}`,
        type: "warning",
        timeout: 30000,
      });
    }

    setTrainingProgress(res.trainingProgress, res.userId);

    ["email", "phone"].forEach((key) => {
      if (res.searchKinds[key]) {
        setCreditsCounter(res[`${key}Credits`], key);
        CREDITS[`${key}Credits`] = res[`${key}Credits`];
      }
    });

    if (["standard", "unlimited"].includes(res.diallerPlan)) {
      setCreditsCounter(res.diallerMinutes, "dialler", res.diallerPlan);
    }

    displayDataProviders(res.dataProviders);
  });

  $("#signOutButton").click(() => {
    yesNoConfirm("Are you sure you would like to sign out?", signOut);
  });

  $("#adminDropdownDiv").toggle(!!getUser().isAdmin);

  if (urlParams.get("isAdmin") === "1") $("#adminDropdownDiv").show();

  wrappedGet({
    url: "/api/users/get_alerts",
    success: (res) => {
      if (res.length > 0) {
        $("#alertsCounterSpan").show();
        $("#alertsLink").removeClass("d-none");
      }
      res.forEach((alert) =>
        alert.kind === "integrationDisconnected"
          ? addIntegrationDisconnectedAlert(alert)
          : addAlert(alert),
      );
    },
  });
};
