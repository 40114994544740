import { getUser, cookieValue, trainingTasks } from "./utils.js";
/* eslint-disable */
export const initializeTracking = () => {
  $(() => {
    // INTERCOM

    const impersonating = cookieValue("impersonating") === "true";
    const local = window.location.hostname === "localhost";
    if (impersonating || local) return;

    const intercomIdentifyUser = (user) => {
      window.intercomSettings = {
        app_id: "my607jub",
        name: user.name,
        email: user.email,
        created_at: user.creationTime,
        user_hash: user.intercomHash,
        "Team Admin": user.isAdmin,
        "Extension Name": user.extensionName,
        "Extension Version": user.extensionVersion,
        "Percent Active Last Month": user.percentActiveLastMonth,
        "User Status": "active",
        Resolution: window.screen.width + "x" + window.screen.height,
        "Screen Width": window.screen.width,
        "Screen Height": window.screen.height,
        kind: user.kind,
        company: {
          company_id: user.teamId,
          name: user.teamId,
        },
      };

      (function () {
        const w = window;
        const ic = w.Intercom;

        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          const d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          // Show intercom window by default on /intercom (used by the gmail extension)
          if (window.location.pathname.includes("/intercom")) {
            w.Intercom("show");
          }
          const l = function () {
            const s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/my607jub";
            const x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === "complete") {
            l();
          } else if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    };

    // DELIGHTED

    !(function (e, t, r, n) {
      if (!e[n]) {
        for (
          var a = (e[n] = []),
            i = [
              "survey",
              "reset",
              "config",
              "init",
              "set",
              "get",
              "event",
              "identify",
              "track",
              "page",
              "screen",
              "group",
              "alias",
            ],
            s = 0;
          s < i.length;
          s++
        ) {
          var c = i[s];
          a[c] =
            a[c] ||
            (function (e) {
              return function () {
                var t = Array.prototype.slice.call(arguments);
                a.push([e, t]);
              };
            })(c);
        }
        a.SNIPPET_VERSION = "1.0.1";
        var o = t.createElement("script");
        (o.type = "text/javascript"),
          (o.async = !0),
          (o.src =
            "https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/" +
            r +
            "/" +
            n +
            ".js");
        var p = t.getElementsByTagName("script")[0];
        p.parentNode.insertBefore(o, p);
      }
    })(window, document, "74mBj8I8vKBDbQq1", "delighted");

    const delightedIdentifyUser = (user) => {
      delighted.survey({
        email: user.email,
        name: user.name,
        createdAt: user.creationTime,
        properties: { company: user.teamId },
      });
    };

    // BIRDIE

    const birdieIdentifyUser = (user) => {
      window.birdieSettings = {
        contact_name: user.name,
        contact_email: user.email,
        contact_id: user.userId,
      };

      window.addEventListener("load", function () {
        var t = document.createElement("script");
        (t.type = "text/javascript"),
          (t.async = !0),
          (t.src = "https://app.birdie.so/widget/embed/ikw3vl20"),
          document.body.appendChild(t);
      });
    };

    // CLARITY

    const clarityIdentifyUser = (user) => {
      window.clarity("identify", user.email);
    };

    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "8cblasg7oy");

    // GET USER

    const user = getUser();
    if (user && Object.keys(user).length) {
      intercomIdentifyUser(user);
      delightedIdentifyUser(user);
      birdieIdentifyUser(user);
      clarityIdentifyUser(user);
    } else {
      // DON'T USE wrappedGet - users will receive 401 and be redirected to login page
      // on third-party sites
      $.get({
        url: "/api/users/get",
        success: (user) => {
          intercomIdentifyUser(user);
          delightedIdentifyUser(user);
          birdieIdentifyUser(user);
          clarityIdentifyUser(user);
        },
        error: () => {
          intercomIdentifyUser({});
        },
      });
    }

    // HEAP

    (window.heap = window.heap || []),
      (heap.load = function (e, t) {
        (window.heap.appid = e), (window.heap.config = t = t || {});
        const r = document.createElement("script");
        (r.type = "text/javascript"),
          (r.async = !0),
          (r.src = `https://cdn.heapanalytics.com/js/heap-${e}.js`);
        const a = document.getElementsByTagName("script")[0];
        a.parentNode.insertBefore(r, a);
        for (
          let n = function (e) {
              return function () {
                heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
              };
            },
            p = [
              "addEventProperties",
              "addUserProperties",
              "clearEventProperties",
              "identify",
              "resetIdentity",
              "removeEventProperty",
              "setEventProperties",
              "track",
              "unsetEventProperty",
            ],
            o = 0;
          o < p.length;
          o++
        )
          heap[p[o]] = n(p[o]);
      });
    heap.load("3679996491");

    // DON'T USE wrappedGet - users will receive 401 and be redirected to login page
    // on third-party sites
    $.get({
      url: "/api/users/heap_properties",
      success: (properties) => {
        const user = getUser() || {};
        const trainingProgress = user.trainingProgress || [];

        heap.identify(properties.userId);

        const trainingTaskIds = Object.values(trainingTasks || {}).reduce(
          (acc, x) => [...acc, ...x.map((y) => y.id)],
          [],
        );

        properties.trainingCompleted = trainingTaskIds.every((x) =>
          trainingProgress.includes(x),
        );

        properties.restrictDomains =
          properties.restrictDomains?.length > 0 ? "true" : "false";

        properties.resolution =
          window.screen.width + "x" + window.screen.height;
        properties.screenWidth = window.screen.width;
        properties.screenHeight = window.screen.height;

        heap.addUserProperties(properties);
      },
    });
  });
};
