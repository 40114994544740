import { addWebsocketListener } from "../../assets/js/src/general/websocket.js";

export const SwApiStore = ({ get, onMessage, init }) => {
  // Store content
  let meta = {
    fetching: false, // For spinners
    firstFetchDone: false, // For skeleton loading / once
  };
  let data = init();

  // Implementation of svelte store contract methods
  const subscribers = new Set();
  const subscribe = (subscriber) => {
    subscribers.add(subscriber);
    subscriber({ meta, data });
    return () => subscribers.delete(subscriber);
  };

  const notifySubscribers = () =>
    subscribers.forEach((subscriber) => subscriber({ meta, data }));

  const update = (updater) => {
    const updated = updater({ meta, data });
    meta = updated.meta;
    data = updated.data;
    notifySubscribers();
  };

  const getAndUpdateStore = async () => {
    meta.fetching = true;
    notifySubscribers();

    return get().then((newData) => {
      meta = {
        fetching: false,
        firstFetchDone: true,
      };
      data = newData;
      notifySubscribers();
      return { data, meta };
    });
  };

  const once = async () =>
    meta.firstFetchDone ? { data, meta } : getAndUpdateStore();

  if (onMessage)
    addWebsocketListener((message) => onMessage({ update, message }));

  return {
    subscribe,
    get: getAndUpdateStore,
    once,
  };
};
