/**
 * @typedef {Object} Request
 * @property {string} path - The API endpoint path.
 * @property {Object<string, string>} [params] - The query parameters for the
 *   request.
 * @property {Object} [body] - The body of the request, used for POST or PUT
 *   methods.
 * @property {"GET" | "POST" | "PUT"} [method] - The HTTP method to use for the
 *   request.
 */

/**
 * Custom error class for API errors.
 *
 * @extends {Error}
 */
class ApiError extends Error {
  /**
   * Creates an instance of ApiError.
   *
   * @param {number} status - The HTTP status code of the error.
   * @param {string} message - The error message.
   */
  constructor(status, message) {
    super(message);
    this.status = status;
    this.name =
      {
        401: "Unauthorized",
        404: "Not Found",
      }[status] || "Unknown Error";
  }
}

/**
 * Handles unauthorized errors.
 *
 * @param {ApiError} err - The error to handle.
 * @throws {ApiError} Throws the error if it is not an unauthorized error.
 */
export const swCatchUnauthorized = (err) => {
  if (err.status === 401) {
    console.error("Unauthorized");
  } else {
    throw err;
  }
};

const url = (apiCall) => {
  const queryString =
    apiCall.params && Object.keys(apiCall.params).length
      ? `?${new URLSearchParams(apiCall.params).toString()}`
      : "";
  return `${apiCall.path}${queryString}`;
};

/**
 * Makes an API request.
 *
 * @template T
 * @param {Request} apiCall - The details of the API call.
 * @returns {Promise<T>} A promise that resolves to the response data.
 * @throws {ApiError} Throws an ApiError if the request fails.
 */
export const swRequest = async (apiCall) =>
  fetch(url(apiCall), {
    credentials: "include",
    body: apiCall.body ? JSON.stringify(apiCall.body) : undefined,
    method: apiCall.method || "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((resp) =>
      resp.ok
        ? resp.json()
        : resp
            .text()
            .then((text) => Promise.reject(new ApiError(resp.status, text))),
    )
    .catch((err) => {
      if (err.status === 401 && window.location.pathname !== "/signin") {
        window.location.href = "/signin";
      }
      throw err;
    });
